<template>
  <div class="d-flex justify-content-center flex-column flex-grow-1 max-w-450">
    <h5>
      {{ $translation.t("I’m creating a legal document as a") }}
    </h5>
    <v-form
      ref="aboutUserForm"
      class="d-flex align-items-center w-100 th-mt-40"
    >
      <v-text-field
        hide-details="auto"
        height="36"
        v-model="userRole"
        :rules="[rules.required]"
        class="th-m-0 th-p-0"
        ref="aboutUserInput"
      />
      <v-btn color="primary" @click="next" class="th-ml-16">
        {{ $translation.t("Start") }}
      </v-btn>
    </v-form>
    <p class="caption th-mt-16">
      {{ $translation.t("Choose one of these options") }}
    </p>
    <div class="th-pl-16 th-mt-16">
      <div v-for="(option, oKey) in options" :key="oKey">
        <v-chip
          :color="isOption(option) ? 'primary' : 'light'"
          class="option th-my-4"
          @click="setOption(option)"
        >
          {{ option }}
        </v-chip>
      </div>
    </div>
  </div>
</template>

<script>
import rules from "@/utils/inputRules";
import { nextQuestionType } from "../../utils/service/Questionnaire";

export default {
  name: "UserRole",
  data: () => ({
    rules,
    userRole: "",
  }),
  computed: {
    options() {
      return [
        "Agency",
        "Compliance Professional",
        "Freelancer",
        "Business Owner",
        "Marketing Professional",
        "Tech Professional",
        "Other",
      ].map((option) => this.$translation.t(option));
    },
  },
  created() {
    this.$axios.put("/api/v1/user/role", {
      role: this.userRole,
    });
  },
  methods: {
    next() {
      if (this.$refs.aboutUserForm.validate()) {
        try {
          this.$axios.put("/api/v1/user/role", {
            role: this.userRole,
          });
        } catch {
          // do nothing
        }

        this.$event("onboarding_user_role_submit", {
          user_role: this.userRole,
        });

        this.$router.push({
          name: "welcome_questionnaire",
          params: { ...this.$route.params, type: nextQuestionType() },
        });
      } else {
        this.$event("onboarding_user_role_form_validation_error");
      }
    },
    setOption(option) {
      this.$event("onboarding_user_role_select", { option });

      if (option?.toLowerCase() === "other") {
        this.userRole = "";
        this.$refs.aboutUserInput?.focus();
        return;
      }

      this.userRole = option;
    },
    isOption(option) {
      if (option?.toLowerCase() === "other") {
        return (
          !!this.userRole &&
          !this.options.find(
            (opt) => opt.toLowerCase() === this.userRole.toLowerCase()
          )
        );
      }

      return this.userRole?.toLowerCase() === option?.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.option {
  border: 1px solid $border-color;

  &.light {
    color: $primary-mid !important;
  }
}
</style>
